import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FaQuoteLeft } from "react-icons/fa";


const Testimonial = () => {
    const testimonialQueryData = useStaticQuery(graphql`
        query testimonialQuery {
            testimonialJson {
                title
                subtitle
                description
           
            },
            file(relativePath: {eq: "images/banner/test4.jpg"}) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1920, maxHeight: 850) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationHeight
                        presentationWidth
                    }
                }
            }
        }
    `);

    const bgImages = testimonialQueryData.file.childImageSharp.fluid;
    const title = testimonialQueryData.testimonialJson.title;
    const subtitle = testimonialQueryData.testimonialJson.subtitle;
    const description = testimonialQueryData.testimonialJson.description;
    return (
        <BackgroundImage fluid={bgImages}>
            <div className="testimonial-area testimonial-style-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="inner">

                                <div className="content">
                                <div className="title">{title}</div>

                                    <div className="icon">
                                        <FaQuoteLeft />
                                    </div>
                                    <div className="info">
                                        <p className="description">{description}</p>
                                        <p className="subtitle">{subtitle}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default Testimonial
