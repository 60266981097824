import React from "react";
import Layout from "../components/layout";
import Content from "../components/seo-services/content";
import Testimonial from "../components/homedefault/testimonial";
import Banner from "../components/banner";
import Seo from "../components/seo";




const SeoServices = () => (
    <Layout>
        <Seo 
        title="Coaching SEO - Référencement naturel et SEO dans le pays de Gex et à Genève - SEO Alternative" 
        description="Besoin d'un regard externe sur votre SEO ? Marie-Luce Beraudo, consultante Web spécialiste du référencement naturel, vous propose des services d'audit SEO et de coaching SEO pour vous aider à réaliser vous-même votre SEO."
        />

        <Banner title="Prestations en coaching SEO (référencement naturel)"></Banner>
        <Testimonial></Testimonial>
        <Content></Content>
    </Layout>

)
export default SeoServices;