import React from "react"
import { Link } from "gatsby"

const ContactFormCoaching = () => {
  return (
    <form
      name="contactCoaching"
      method="POST"
      data-netlify="true"
      action="/merci"
      
    >
      <input type="hidden" name="form-name" value="contactCoaching" />
      <div className="form-group">
        <label htmlFor="name">Votre nom*</label>
        <input type="text" name="name" id="name" required />
      </div>

      <div className="form-group">
        <label htmlFor="email">Votre e-mail*</label>
        <input type="email" name="email" id="email" required />
      </div>
      <div className="form-group">
        <label htmlFor="tel">Votre numéro de téléphone*</label>
        <input type="tel" name="tel" id="tel" required />
      </div>

      <div className="form-group">
        <label htmlFor="subject">Votre besoin*</label>
        <select name="subject" id="subject" required>
          <option value="">Choisissez dans la liste ci-dessous</option>
          <option value="audit">Audit SEO</option>
          <option value="coaching">Coaching SEO</option>
          <option value="suivi">Suivi SEO</option>
          <option value="autre">Autre</option>

        </select>
      </div>
      <div className="form-group">
        <label htmlFor="url">L'url de votre site Web*</label>
        <input type="text" name="url" id="url" required />
      </div>

      <div className="form-group">
        <label htmlFor="message">Votre message</label>
        <textarea name="message" id="message" required></textarea>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          paddingBottom: "1rem",
        }}
      >
        <input
          type="checkbox"
          id="agreement"
          name="agreement"
          style={{ margin: "1rem" }}
          required
        ></input>

        <label htmlFor="agreement">
          En cochant cette case, j'accepte que les données transmises via ce
          formulaire soient utilisées par SEO Alternative dans le cadre d'une
          demande devis/d'information et de la relation commerciale qui peut en
          découler. Pour plus d'informations, consultez mes{" "}
          <Link to="/mentions">
            <span className="theme-color">mentions légales</span>
          </Link>
        </label>
      </div>

      <div className="form-submit">
        <button className="rn-button" type="submit">
          Envoyer
        </button>
      </div>
    </form>
  )
}

export default ContactFormCoaching
