import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Screenshot from "./extrait-rapport-audit-SEO.png"
import Logo from "./logo0.png"
import { Link } from "gatsby"
import ContactFormCoaching from "../../elements/contact/contactformCoaching"
import ContactButton from "../contactbutton"

const Content = () => {
  const contentQueryData = useStaticQuery(graphql`
    query ContentSeoQuery {
      file(relativePath: { eq: "images/banner/eye5.png" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const BgshapeImages = contentQueryData.file.childImageSharp.fixed

  return (
    <div className="blog-details-wrapper pt--70 pb--100 bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="post-single-title">
              <h2 className="post-title">
                Est-ce une bonne stratégie d'externaliser son SEO ?
              </h2>
            </div>
            <div className="post-content">
              <p>
                Non, pas forcément. En effet, externaliser votre référencement
                naturel, c&rsquo;est prendre le risque de confier votre
                marketing &agrave; une agence qui ne conna&icirc;t ni votre
                entreprise ni votre secteur d&rsquo;activit&eacute;.
              </p>

              <p>
                Cette démarche est d'autant plus sensible &agrave; notre
                &eacute;poque o&ugrave; le contenu est devenu la base du
                r&eacute;f&eacute;rencement naturel. &laquo; Content is king !
                &raquo; disait d&eacute;j&agrave; Bill Gates en 1996. C'était
                déjà vrai à l'époque, ça l'est encore plus maintenant.
              </p>
              <p>
                Or, pour que votre contenu soit pertinent, il faut qu&rsquo;il
                ait &eacute;t&eacute; produit par des personnes qui connaissent
                votre entreprise, votre marque ainsi que les
                probl&eacute;matiques auxquelles vos produits/services doivent
                r&eacute;pondre. En d'autres termes : vous-même.
              </p>
              <p>
                Voil&agrave; pourquoi je vous propose une approche alternative :{" "}
                <strong>
                  je vous aide &agrave; g&eacute;rer vous-m&ecirc;me votre SEO
                </strong>{" "}
                en me positionnant en tant que <strong>coach SEO</strong>.
              </p>
              <p>
                Vous avez peut-&ecirc;tre d&eacute;j&agrave; des connaissances
                en SEO mais vous avez le sentiment de stagner. Vous avez
                essay&eacute; des astuces trouv&eacute;es sur le Web. Vous avez
                peut-&ecirc;tre d&eacute;j&agrave; plac&eacute; des
                mots-cl&eacute;s strat&eacute;giques dans votre code source ou
                r&eacute;dig&eacute; du contenu. Le probl&egrave;me est que vous
                n&rsquo;&ecirc;tes pas s&ucirc;r que votre approche est la bonne
                et vous avez besoin d&rsquo;un regard externe, comme celui
                d&rsquo;un consultant sp&eacute;cialis&eacute; en SEO, pour vous
                aider &agrave;{" "}
                <strong>
                  d&eacute;finir une strat&eacute;gie pertinente sur la
                  dur&eacute;e
                </strong>
                .
              </p>
              <div className="post-single-title">
                <h2 className="post-title">
                  Pourquoi choisir SEO Alternative pour du coaching SEO ?
                </h2>
                <p>
                  Pourquoi choisir SEO Alternative pour vous coacher et vous
                  aider &agrave; d&eacute;finir une strat&eacute;gie SEO ?
                </p>
                <p>
                  Ce qui me distingue d'autres consultants SEO est mon double
                  profil : j'ai des comp&eacute;tences &agrave; la fois du
                  c&ocirc;t&eacute; <strong>technique</strong> et du
                  c&ocirc;t&eacute; <strong>marketing</strong>.
                </p>
                <p>
                  C&ocirc;t&eacute; technique : je cr&eacute;e des sites Web
                  depuis 15 ans. J'ai pu observer l'&eacute;volution des outils
                  et des m&eacute;thodes pour g&eacute;rer le SEO. Je connais
                  les probl&eacute;matiques techniques des sites Web et les
                  contraintes auxquelles font face les
                  r&eacute;f&eacute;renceurs (tous les outils ne se valent pas
                  question SEO).
                </p>
                <p>
                  C&ocirc;t&eacute; marketing : depuis quelques ann&eacute;es,
                  j'ai &eacute;t&eacute; de plus en plus souvent amen&eacute;e
                  &agrave; g&eacute;rer le marketing digital de mes clients.
                  Cela inclut le SEO mais aussi le marketing de contenu, le
                  community management et la mise en place d'outils de
                  communication digitaux. Je donne &eacute;galement des
                  formations en marketing digital et SEO aupr&egrave;s de
                  professionnels.
                </p>
                <p>
                  Pour en savoir plus sur mon profil et mon parcours, je vous
                  invite à consulter mon{" "}
                  <a
                    href="https://www.linkedin.com/in/marieluceberaudo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong className="theme-color">CV LinkedIn</strong>
                  </a>
                </p>
              </div>
              <div className="post-single-title">
                <h2 className="post-title">
                  Ma démarche : comment se déroule un coaching SEO ?
                </h2>
              </div>
              <p>
                Un coaching SEO démarre la plupart du temps par un{" "}
                <strong>audit SEO</strong>. En effet, pour qu’un coaching SEO
                puisse être efficace, il faut qu’un travail d’analyse de votre
                site Web ait déjà été effectué au préalable et qu’un rapport
                d’audit ait été rédigé. Après l’audit, j’organise une ou
                plusieurs séances de coaching avec vous pour appuyer mes
                recommandations, soit directement dans les locaux de votre
                entreprise soit à distance en visioconférence. Les coachings
                peuvent se poursuivre par un suivi régulier de votre SEO sur
                quelques semaines ou quelques mois.
              </p>
              <h3>1ère étape : l’audit SEO</h3>
              <p>
                Lorsque je réalise l'audit SEO d'un site Web, j’analyse à la
                fois l’aspect technique et la qualité du contenu.
              </p>
              <p>
                Pour chaque audit, je m’appuie sur un outil informatique qui
                m’aide à analyser jusqu'aux pages les plus profondes de votre
                site Web. L'avantage de cet outil est qu'il réduit
                considérablement le travail du référenceur mais il reste
                insuffisant pour un audit SEO de qualité. En effet,{" "}
                <strong>
                  aucun outil informatique ne peut analyser votre contenu d’un
                  point de vue marketing
                </strong>{" "}
                comme un humain le ferait. Un regard humain nourri d’une
                approche marketing constitue la base de tout audit SEO.
              </p>
              <p>
                <strong>
                  Toutes les recommandations données par un outil d’analyse SEO
                  devraient être filtrées par un expert en SEO avant d’être
                  appliquées.
                </strong>
              </p>
              <p>
                Mon audit SEO est toujours matérialisé par un rapport écrit que
                je rédige moi-même. Ce rapport contient des recommandations
                structurées en différentes étapes.<br></br>
                <figure>
                  <img
                    src={Screenshot}
                    alt="Capture d'écran rapport audit SEO"
                    style={{
                      width: "500px",
                      border: "solid 2px var(--color-body)",
                    }}
                  />
                  <figcaption>Capture d'écran - Rapport d'audit SEO</figcaption>
                </figure>
              </p>
              <p>
                Je vous invite à lire également mon article :{" "}
                <strong>
                  <Link to="/pourquoi-faire-un-audit-seo/">
                    Pourquoi faire un audit SEO ?
                  </Link>
                </strong>{" "}
                et à consulter ma{" "}
                <strong>
                  <Link to="/ma-checklist-daudit-technique-seo-les-questions-se-poser-avant-un-audit-seo/">
                    checklist SEO
                  </Link>
                </strong>
              </p>
              <h3>2ème étape : le coaching SEO</h3>
              <p>
                Le coaching SEO consiste en un rendez-vous avec vous qui doit
                avoir lieu après l’audit SEO et après que vous avez pu prendre
                connaissance du rapport d’audit. Ce rendez-vous peut être
                organisé en présentiel (dans vos locaux) ou à distance en
                visioconférence.
              </p>
              <p>
                Il est utile, lors de la séance de coaching, que toutes les
                personnes amenées à travailler sur votre SEO soient présentes.
                Lors de ce rendez-vous, je vous aide à mieux comprendre le
                rapport rédigé suite à l’audit SEO, je réponds aux questions que
                vous pouvez avoir sur le SEO et je vous aide à définir une
                stratégie qui répond à vos problématiques.{" "}
              </p>
              <p>
                Généralement, il faut prévoir au moins 2 heures pour une
                première séance de coaching SEO. Selon les cas, on peut prévoir
                ensuite d’autres séances ou des séances plus longues.
              </p>
              <p>
                {" "}
                <ContactButton texte="Me contacter pour un coaching SEO" lien="#contactCoaching"></ContactButton>
              </p>
              <h3>3ème étape : le suivi SEO</h3>
              <p>
                Les étapes d’audit SEO et de coaching SEO nous ont déjà permis
                de faire ressortir des améliorations à apporter à votre site Web
                et de définir une stratégie en plusieurs étapes. Cependant,
                toutes ces recommandations que je vous apporte peuvent être
                difficiles à mettre en place soit parce que le service marketing
                n’a pas beaucoup de ressources à consacrer au SEO soit tout
                simplement pour des raisons de gestion de priorité, voire de
                simple motivation…
              </p>
              <p>
                Le suivi SEO que je propose consiste à vous aider de plusieurs
                façons possibles :
              </p>

              <ul>
                <li>
                  Je peux prendre moi-même en charge les actions recommandées
                  suite à mon audit et mon coaching
                </li>
                <li>
                  Je peux vous former aux problématiques SEO qui vous semblent
                  obscures
                </li>
                <li>
                  Je peux vous aider à mettre en place une stratégie de
                  marketing de contenu, notamment à trouver des idées de contenu
                  et à rédiger du contenu avec une approche SEO
                </li>
                <li>
                  Je peux servir d’interface avec votre prestataire externe pour
                  les problématiques SEO
                </li>
              </ul>
              <p>
                De même que pour l’audit SEO, mon suivi SEO inclut la rédaction
                d’un rapport régulier (selon les cas, ce rapport peut être
                mensuel, trimestriel ou annuel).
              </p>
              <p>
                <strong>Combien de temps dure un suivi SEO ?</strong>
              </p>
              <p>
                Le suivi SEO peut durer de quelques semaines à quelques mois,
                voire un an ou plus selon les cas. Ce suivi consiste
                généralement en quelques journées par mois ou quelques heures
                par semaine, mon approche consistant à réduire le nombre
                d’heures au fil du temps de façon à ce que vous soyez autonomes
                par rapport à votre stratégie SEO.
              </p>
              <p>
                <strong>
                  Contactez-moi pour un coaching SEO - Je réside dans le pays de Gex près de Genève et canton de Vaud et je travaille avec des clients dans toute la France
                </strong>
              </p>
              <p>
                {" "}
                <ContactButton texte="Me contacter pour un coaching SEO" lien="#contactCoaching"></ContactButton>
              </p>
            </div>
          </div>
          <div className="col-lg-5" id="contactCoaching">
            <div className="thumbnail">
              <div className="thumbnail-inner text-center">
                <Img
                  className="portfolio-images"
                  fixed={BgshapeImages}
                  alt="Faut-il externaliser son SEO ?"
                />
              </div>
            </div>
            <div
              style={{ border: "solid 1px var(--color-body)", padding: "2rem" }}
            >
              <div className="logo text-center">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="SEO Alternative - Coaching SEO"
                    style={{
                      width: "500px",
                    }}
                  />{" "}
                </Link>
              </div>
              <h1
                className="h5"
                style={{
                  color: "var(--color-primary)",
                  fontWeight: "normal",
                  lineHeight: 1.5,
                }}
              >
                Contactez-moi pour vos besoins en coaching SEO via le formulaire
                ci-dessous
              </h1>
              <p style={{ color: "var(--color-primary)", lineHeight: 1.5 }}>
                Vous pouvez également me téléphoner au{" "}
                <a href="tel:+33786463681">07 86 46 36 81</a>
              </p>
              <ContactFormCoaching></ContactFormCoaching>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Content
